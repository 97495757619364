import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Image from "gatsby-image"

import Nav from "./nav"
import styles from "./header.module.css"

const Header = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          fileName: file(relativePath: { eq: "images/sandra-headshot.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Nav />
          <header className={styles.siteHeader}>
            <div className={styles.siteHeaderContainer}>
              <Image className={styles.siteLogo} fluid={data.fileName.childImageSharp.fluid} alt="" />
              <p className={styles.siteTitle}>
                <Link to="/">{data.site.siteMetadata.title}</Link>
              </p>
            </div>
          </header>
        </>
      )}
    />
  )
}

export default Header
