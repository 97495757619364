import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"

import styles from "./nav.module.css"

const Nav = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          facebook
          instagram
          twitter
        }
      }
      twitter: file(relativePath: { eq: "images/icons/icon--twitter.svg" }) {
        publicURL
      }
      instagram: file(
        relativePath: { eq: "images/icons/icon--instagram.png" }
      ) {
        publicURL
      }
      facebook: file(relativePath: { eq: "images/icons/icon--facebook.svg" }) {
        publicURL
      }
    }
  `)
  return (
    <nav className={styles.mainNav}>
      <div className="container">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/books">Books</Link>
          </li>
          <li>
            <Link to="/#about">About</Link>
          </li>
          <li>
            <Link to="/#educators">Educators</Link>
          </li>
          <li>
            <Link to="/#contact">Contact</Link>
          </li>
          <aside className={styles.socialLinks}>
            <li className={styles.social}>
              <a
                href={`https://twitter.com/${data.site.siteMetadata.twitter}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={data.twitter.publicURL} alt="Twitter logo" />
              </a>
            </li>
            <li className={styles.social}>
              <a
                href={`https://facebook.com/${data.site.siteMetadata.facebook}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={data.facebook.publicURL} alt="Facebook logo" />
              </a>
            </li>
          </aside>
        </ul>
      </div>
    </nav>
  )
}

export default Nav
